import React, {Component} from 'react';
import {Route, Switch, BrowserRouter, Redirect} from 'react-router-dom';
import Loadable from 'react-loadable';
import PropTypes from 'prop-types';
import './assets/styles/global.css';
import {basePath} from './app-config/config.js';
import {loadMap} from './common/map.js';
import queryString from 'query-string';

const Home = Loadable({
  loader: () => import('./pages/Home'),
  loading: () => <div></div>,
});
const ChooseShop = Loadable({
  loader: async () => {
    await loadMap();
    return import('./pages/ChooseShop');
  },
  loading: () => <div></div>,
});

const Reservation = Loadable({
  loader: async () => {
    await loadMap();
    return import('./pages/Reservation/Reservation');
  },
  loading: () => <div></div>,
});
const CreateOrder = Loadable({
  loader: async () => {
    await loadMap();
    return import('./pages/CreateOrder/CreateOrder');
  },
  loading: () => <div></div>,
});
const QROrder = Loadable({
  loader: () => import('./pages/qrorder'),
  loading: () => <div></div>,
});
const Appointment = Loadable({
  loader: () => import('./pages/Appointment'),
  loading: () => <div></div>,
});
const OrdersView = Loadable({
  loader: () => import('./pages/orders/OrdersView'),
  loading: () => <div></div>,
});
const ServicingOrders = Loadable({
  loader: () => import('./pages/servicing-orders/ServicingOrders'),
  loading: () => <div></div>,
});
const ServicingOrderView = Loadable({
  loader: () => import('./pages/servicing-order/ServicingOrderView'),
  loading: () => <div></div>,
});
const ClaimTaobaoOrder = Loadable({
  loader: () => import('./pages/claim-taobao-order/ClaimTaobaoOrder'),
  loading: () => <div></div>,
});
const OrderView = Loadable({
  loader: () => import('./pages/order/OrderView'),
  loading: () => <div></div>,
});
const OrderStagesView = Loadable({
  loader: () => import('./pages/order-stages/OrderStagesView'),
  loading: () => <div></div>,
});
const LoginView = Loadable({
  loader: () => import('./pages/login/LoginView'),
  loading: () => <div></div>,
});
const BindWeChat = Loadable({
  loader: () => import('./pages/login/BindWeChat'),
  loading: () => <div />,
});
const UnbindWeChat = Loadable({
  loader: () => import('./pages/login/UnbindWeChat'),
  loading: () => <div />,
});
const WechatAuth = Loadable({
  loader: () => import('./pages/login/WechatAuth'),
  loading: () => <div />,
});
const ClearSession = Loadable({
  loader: () => import('./pages/login/ClearSession'),
  loading: () => <div />,
});
const NotFound = Loadable({
  loader: () => import('./pages/NotFound'),
  loading: () => <div></div>,
});
const ServiceFlow = Loadable({
  loader: () => import('./pages/ServiceFlow'),
  loading: () => <div></div>,
});
const Mine = Loadable({
  loader: () => import('./pages/Mine'),
  loading: () => <div></div>,
});
const CancelOrder = Loadable({
  loader: () => import('./pages/CancelOrder/CancelOrder'),
  loading: () => <div></div>,
});
const Contactus = Loadable({
  loader: () => import('./pages/Contactus'),
  loading: () => <div></div>,
});
const SelectModel = Loadable({
  loader: () => import('./pages/SelectFailure/SelectModel'),
  loading: () => <div></div>,
});
const SelectFailure = Loadable({
  loader: () => import('./pages/SelectFailure/SelectFailure'),
  loading: () => <div></div>,
});
const Pay = Loadable({
  loader: () => import('./pages/pay/Pay'),
  loading: () => <div></div>,
});
const PayResult = Loadable({
  loader: () => import('./pages/pay/PayResult'),
  loading: () => <div></div>,
});
const DiscountCoupon = Loadable({
  loader: () => import('./pages/discount-coupon/DiscountCoupon'),
  loading: () => <div></div>,
});
const DiscountDetail = Loadable({
  loader: () => import('./pages/discount-coupon/DiscountDetail'),
  loading: () => <div></div>,
});
const DiscountSelect = Loadable({
  loader: () => import('./pages/discount-coupon/DiscountSelect'),
  loading: () => <div></div>,
});
const Welfare = Loadable({
  loader: () => import('./pages/welfare/Welfare'),
  loading: () => <div></div>,
});

const ServiceClosePrompt = Loadable({
  loader: () => import('./pages/app-prompt/ServiceClosePrompt'),
  loading: () => <div></div>,
});

const StoreDetail = Loadable({
  loader: async () => {
    await loadMap();
    return import('./pages/store-detail/StoreDetail');
  },
  loading: () => <div></div>,
});

const prefix = (key) => {
  return `qjw2c#${key}`;
};
window.saveSessionStorage = (key, value) => {
  window.sessionStorage.setItem(prefix(key), value);
};
window.getSessionStorage = (key) => {
  return window.sessionStorage.getItem(prefix(key));
};
window.removeSessionStorage = (key) => {
  window.sessionStorage.removeItem(prefix(key));
};
window.saveLocalStorage = (key, value) => {
  if (window.notSupportLocalStorage) {
    window.saveSessionStorage(key, value);
    return;
  }
  window.localStorage.setItem(prefix(key), value);
};
window.getLocalStorage = (key) => {
  if (window.notSupportLocalStorage) {
    return window.getSessionStorage(key);
  }
  return window.localStorage.getItem(prefix(key));
};
window.removeLocalStorage = (key) => {
  if (window.notSupportLocalStorage) {
    window.removeSessionStorage(key);
    return;
  }
  window.localStorage.removeItem(prefix(key));
};
window.checkReturnUrl = () => {
  const returnUrl = window.getSessionStorage('returnUrl');
  if (returnUrl) {
    window.location.href = returnUrl;
    return true;
  }
  return false;
};

class LocationListener extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  componentDidMount() {
    this.handleLocationChange(this.context.router.history.location);
    this.unlisten = this.context.router.history.listen(this.handleLocationChange);
  }

  componentWillUnmount() {
    this.unlisten();
  }

  handleLocationChange(location) {
    window.scrollTo(0, 0);
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
  }

  render() {
    return this.props.children;
  }
}

class App extends Component {
  constructor(props) {
    super(props);
    const query = queryString.parse(window.location.search);
    query.dis && window.saveSessionStorage('dis', query.dis);
    query.returnUrl && window.saveSessionStorage('returnUrl', query.returnUrl);
  }

  render() {
    return (
      <BrowserRouter basename={basePath}>
        <LocationListener>
          <Switch>
            <Route exact path="/" component={Home} />
            <Redirect exact from="/onsiteservice" to="/app/serviceclose" />
            <Route exact path="/reservation" component={Reservation} />
            <Route exact path="/chooseshop" component={ChooseShop} />
            <Route exact path="/item" component={QROrder} />
            <Route exact path="/appointment" component={Appointment} />
            <Route exact path="/orders" component={OrdersView} />
            <Route exact path="/orders/:id" component={OrderView} />
            <Route exact path="/servicing-orders" component={ServicingOrders} />
            <Route exact path="/servicing-orders/:id" component={ServicingOrderView} />
            <Route exact path="/orders/:id/stages" component={OrderStagesView} />
            <Route exact path="/login" component={LoginView} />
            <Route exact path="/login/wechat" component={WechatAuth} />
            <Route exact path="/clear" component={ClearSession} />
            <Route exact path="/bind/wechat" component={BindWeChat} />
            <Route exact path="/unbind" component={UnbindWeChat} />
            <Route exact path="/serviceflow" component={ServiceFlow} />
            <Route exact path="/mine" component={Mine} />
            <Route exact path="/contactus" component={Contactus} />
            <Route exact path="/aboutus" component={Contactus} />
            <Route exact path="/faq" component={Contactus} />
            <Route exact path="/cancelorder/:id" component={CancelOrder} />
            <Route exact path="/createorder" component={CreateOrder} />
            <Route exact path="/failure/select/:id" component={SelectFailure} />
            <Route exact path="/model/select" component={SelectModel} />
            <Route exact path="/pay/:id" component={Pay} />
            <Route exact path="/payResult" component={PayResult} />
            <Route exact path="/discount/select" component={DiscountSelect} />
            <Route exact path="/discount/:id" component={DiscountDetail} />
            <Route exact path="/discount" component={DiscountCoupon} />
            <Route exact path="/welfare" component={Welfare} />
            <Route exact path="/app/serviceclose" component={ServiceClosePrompt} />
            <Route exact path="/store/:id" component={StoreDetail} />
            <Route exact path="/claim-taobao-orders" component={ClaimTaobaoOrder} />

            <Route exact path="*" component={NotFound} />
          </Switch>
        </LocationListener>
      </BrowserRouter>
    );
  }
}

export default App;
