const loadScript = async (url) => {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    if (document.documentMode === 8) {
      script.onreadystatechange = function() {
        if (script.readyState === 'loaded') {
          resolve();
        }
      };
    } else {
      script.onload = function() {
        resolve();
      };
    }
    script.src = url;
    document.body.appendChild(script);
  });
};

export const loadMap = async () => {
  if (window.AMap) {
    return;
  }

  await loadScript(
    'https://webapi.amap.com/maps?v=2.0&key=a644bf30aedf01d322b0a847ed66bfc9&plugin=AMap.Geocoder,AMap.Geolocation,AMap.AutoComplete,AMap.PlaceSearch,AMap.MarkerCluster',
  );
};
