export const apiUrl = 'https://testgateway.pisen.com.cn';
export const baseUrl = `${apiUrl}/api/qjw.user2.api`;
export const appKey = '1871672';
export const appSecret = 'c8e9a15f63407c2d';
export const basePath = '/mobile/';
export const tel = '4000889898';
export const wechatAppId = 'wxc97ddfbebf0f311d';
export const promotionUrl = 'http://hulk.dev.pinsunsoft.cn/fx/Merchant/MyPromotion';

export const ContactUs = '468194628979396608';
export const AboutUs = '469180808030326784';
export const FAQ = '468199623237832704';
export const WarefareADLevel = '437308539654770688';
export const HomeBannerADLevel = '579816348396818432';
export const HomeHotADLevel = '579809718770733056';

export const CF_CouponChannel = 2;
export const defaultServeUrl =
  'https://webchat-bj.clink.cn/chat.html?accessId=b86dd8fc-6fdb-41ad-954f-c26a8a10d77b&language=zh_CN';
export const servicingOrdersServeUrl =
  'https://webchat-bj.clink.cn/chat.html?accessId=52fc15e9-1964-4e5c-bc0e-23b0b0df8660&language=zh_CN';
export const scanServeUrl =
  'https://webchat-bj.clink.cn/chat.html?accessId=720a1e94-ed49-47bb-bf17-f0f7c4199274&language=zh_CN';
export const storesServeUrl =
  'https://webchat-bj.clink.cn/chat.html?accessId=f5d02bc1-2b96-40b3-85e2-2b0713b6f889&language=zh_CN';
export const storeListUrl = 'https://web.qjw.pinsunsoft.cn/DrdMapStoreQjw/Store/List';
